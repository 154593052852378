import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FaMoon } from '@react-icons/all-files/fa/FaMoon';
import { FaSun } from '@react-icons/all-files/fa/FaSun';
import logo from '../assets/images/logo.svg';

const NavCopyrightStyled = styled.div`
  background: var(--matteBlack);
  padding: 4rem 0;
  max-width: var(--wrapper);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-gap: 2rem;
  align-items: center;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    justify-items: center;
  }
  img {
    height: 27px;
    filter: brightness(0) invert(1);
  }
  p {
    color: var(--white);
    font-size: 14px;
    margin: 0px;
  }
  span {
    cursor: pointer;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;

export default function NavCopyright() {
  function toggleTheme(e) {
    if (e.currentTarget.id == 'lightTheme') {
      document.body.classList.remove('dark');
    } else {
      document.body.classList.add('dark');
    }
  }
  return (
    <NavCopyrightStyled>
      <Link to="/">
        <img src={logo} alt="Bont Cycling Logo" />
      </Link>
      <div>
        <p>
          Bont Cycling Pty Ltd {new Date().getFullYear()}. All Rights Reserved. ABN 38 602 266 526
        </p>
      </div>
    </NavCopyrightStyled>
  );
}
